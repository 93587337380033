.main {
  .layout {
    width: calc(100% - 280px);
    margin-left: auto;
    padding: 0 60px;

    @media only screen and (max-width: 1600px) {
      padding: 0 30px;
    }
  }

  &.open {
    transition: all 0.5s;

    nav.sliderbar {
      width: 100px;
      transition: all 0.5s;
      top: 10px;

      .navbar-toggler {
        width: 90px;
        height: 83px;
        padding: 18px;

        .navbar-toggler-icon {
          background: url(./images/arrow-left.png) center center no-repeat;
        }
      }

      .navbar-brand {
        padding: 75px 0 20px;

        h3 {
          display: none;
        }
      }

      .navbar-collapse {
        height: calc(100vh - 154px);

        ul {
          padding: 0 20px;

          li {
            a.nav-link {
              width: 60px;
              height: 60px;
              border-radius: 30px;

              i {
                margin: 0;
                font-size: 18px;
                display: block;
                text-align: center;
              }

              span {
                display: none;
              }
            }
          }
        }
      }
    }

    .layout {
      width: calc(100% - 100px);
      transition: all 0.5s;
    }
  }
}

.calendar-group {
  lable {
    display: block;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
}

.react-datepicker-wrapper input.form-control {
  width: 125px;
  text-align: center;
}

.greenBG {
  .react-datepicker-wrapper .form-control {
    background-color: #e5fbe5 !important;
  }
}

.redBG {
  .react-datepicker-wrapper .form-control {
    background-color: #ffe5e5 !important;
  }
}

.disabled-text {
  opacity: 0.7;
  cursor: not-allowed;
}

.read-only {
  background-color: #dddddd80 !important;
  cursor: not-allowed !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.padding-8 {
  padding: 8px !important;
}
